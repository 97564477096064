export const environment = {
    apiIntraNet: 'https://api.intranet.topocart.dev.br',
    apiUserGeo360Permission: 'https://plataforma.apps.geo360.topocart.dev.br/permissoes',
    apiUserGeo360EtopocartPermission: 'https://plataforma.web.etopocart.com/permissoes',
    apiUserGeo360MesquitaPermission: 'https://plataforma.geo360.com.br/permissoes',
    urlPowerBiCusto: 'https://app.powerbi.com/view?r=eyJrIjoiNDczNDk1MjUtODE3MS00OWYyLWFkYTItN2Y0NTdjODc5NmFiIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiAngra: 'https://app.powerbi.com/view?r=eyJrIjoiZmQ2MGM4NzMtZDVjMC00NjlkLTkxZTYtNjBmOWE5YWUyNjAwIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiCodevasf: 'https://app.powerbi.com/view?r=eyJrIjoiYWMwMDEwMzItMGUzOS00NjNjLTgyODAtNDRmY2U0MTUzMjhiIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiCodevasfProducao: 'https://app.powerbi.com/view?r=eyJrIjoiNjcxYTA5YjktMDk3ZC00MWY1LTkzMGEtYWMwYTIzZWRjM2I3IiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiSabara: 'https://app.powerbi.com/view?r=eyJrIjoiMWViNjcyNjEtNjJjNC00NTQ2LWE2MmQtNzBkMWVlNDIxMzgxIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiAmapa: 'https://app.powerbi.com/view?r=eyJrIjoiYjYxNTc2MDEtMzhiNC00M2UwLTk1MTktN2VlZDViYzY4MWI5IiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiSeger: 'https://app.powerbi.com/view?r=eyJrIjoiMDVmMzExYWEtYzYwZC00ZGE1LTg4NTAtN2JkZmZmYmFiYzZkIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiAlexania: 'https://app.powerbi.com/view?r=eyJrIjoiNTg2NGMxMDItNmRjOS00OTcxLWI4MmItOTk2MTU4NjQyNDc4IiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiAparecidaGO: 'https://app.powerbi.com/view?r=eyJrIjoiYjc4MTc3ZDEtZjYxOC00OWY1LTljMDAtN2U1MmE4NWE3NGFhIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiAraguaina: 'https://app.powerbi.com/view?r=eyJrIjoiYzM5NmU1ZTMtMjViNy00N2NkLTgxMzMtYmI2NmZiMzk4YTE5IiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiGurupi: 'https://app.powerbi.com/view?r=eyJrIjoiNzcyMmQwYjItNWQyZC00Mjc3LWFlOGQtYzE1M2MyMGRlNzc1IiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiManaus: 'https://app.powerbi.com/view?r=eyJrIjoiMDY5ZTFjZjAtZmY3ZS00MDRmLTk2MGEtYTgzZDFmMzNiNzdjIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiMariana: 'https://app.powerbi.com/view?r=eyJrIjoiMmU5MzA5YzAtYWRjOC00YzNhLTk0YjUtY2U5Njc5YmQxY2NmIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiParauapebas: 'https://app.powerbi.com/view?r=eyJrIjoiNTQzNmQ2NTAtOTBlMC00NGMwLTgyMTYtODI1YzFiNzNiMjhjIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiRibeiraoNeves: 'https://app.powerbi.com/view?r=eyJrIjoiMDBiMWIzYzktM2E1Zi00MzRlLWIzMTctYWE2YTk5M2Q0MDIwIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiRioBranco: 'https://app.powerbi.com/view?r=eyJrIjoiMTBiY2U1NWUtNTA3MC00OTY5LThjZDktZTY3Y2JhOTczZDgxIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiSAJ: 'https://app.powerbi.com/view?r=eyJrIjoiNjk5ZDg1YjEtYzhjZC00YTE5LWEwNjctNTIzY2Y5NzdhNWM1IiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiVitoriaConquista: 'https://app.powerbi.com/view?r=eyJrIjoiNjkzNGVlMGQtNGNkMi00ZGI4LWEyYmYtYWVmMGUxODg4NWM3IiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiMaracanau: 'https://app.powerbi.com/view?r=eyJrIjoiZGY0YjRjNDctYTVjYi00MmExLWJkNjYtNjY4ZGJjODUxYjIxIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiCadastro: 'https://app.powerbi.com/view?r=eyJrIjoiOTkxOThmYzAtMzBlYy00ZmViLWExZmMtYzI4YzYxOTI1NWZkIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiProducao: 'https://app.powerbi.com/view?r=eyJrIjoiOWY4NTQ4ZGEtMmZjNy00YzFjLWJkNzQtNGJmZTI3ZTQwNjY0IiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiAviacao: 'https://app.powerbi.com/view?r=eyJrIjoiMmIxMzM3ZDYtOTAzYy00MmNjLWI0Y2EtZjAxMzk0ODJkMTFhIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiIndicadoresSociais: 'https://app.powerbi.com/view?r=eyJrIjoiZTEyNGYzOTEtMDA1Mi00OWVhLWE3NWUtZjZmMDQ2YTk5MDAzIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiAtividades: 'https://app.powerbi.com/view?r=eyJrIjoiNDNiYTk1ODktZDI0Yi00NjUxLThiZWYtYzFmY2E1ZTkyYWFhIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiGerencia: 'https://app.powerbi.com/view?r=eyJrIjoiOWMxMTA3ZmEtMmMxYy00ODIwLTlmOWQtZTY4MTNjNzg0ZjgxIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9',
    urlPowerBiRelatorioExtrato: 'https://app.powerbi.com/view?r=eyJrIjoiNTk2YWI0NGQtYjkwNy00MDljLWJmYmEtNDk1ZmE4Mzg3ZWZiIiwidCI6ImJlMzEwMzkxLTRhNDctNDFiNC1iNDc5LThjODA1ZDkxNjM3MyJ9'
}