import { Route } from "../interfaces/customSelectBox";

export const routesPrefeitura: Route[] = [
    { id: 1, path: "/alexania", name: "Alexânia" },
    { id: 2, path: "/amapa", name: "Amapá" },
    { id: 3, path: "/angra", name: "Angra dos Reis" },
    { id: 4, path: "/aparecidagoiania", name: "Aparecida de Goiânia" },
    { id: 5, path: "/araguaina", name: "Araguaína" },
    { id: 6, path: "/gurupi", name: "Gurupi" },
    { id: 7, path: "/manaus", name: "Manaus" },
    { id: 8, path: "/mariana", name: "Mariana" },
    { id: 9, path: "/maracanau", name: "Maracanaú" },
    { id: 10, path: "/parauapebas", name: "Parauapebas" },
    { id: 11, path: "/ribeiraoneves", name: "Ribeirão das Neves" },
    { id: 12, path: "/riobranco", name: "Rio Branco" },
    { id: 13, path: "/sabara", name: "Sabará" },
    { id: 14, path: "/santoantonio", name: "Santo Antônio de Jesus" },
    { id: 15, path: "/seger", name: "Espírito Santo" },
    { id: 17, path: "/vitoriaconquista", name: "Vitória da Conquista" },
    { id: 18, path: "/codevasf", name: "Codevasf" },
    { id: 19, path: "/codevasf-producao", name: "Codevasf (Produção)"}
].sort((a, b) => a.name.localeCompare(b.name));

export const routesInternalBase: Route[] = [
    { id: 1, path: "/atividades", name: "Gerência Execução (até abril/2024)" },
    { id: 2, path: "/aviacao", name: "Painel de Aviação" },
    { id: 3, path: "/cadastro", name: "Controle de Cadastro (até abril/2024)" },
    { id: 4, path: "/indicadores-sociais", name: "Painel de Indicadores Sociais" },
    { id: 5, path: "/producao", name: "Controle de Produção (até abril/2024)" },
].sort((a, b) => a.name.localeCompare(b.name));;

export const routeGestor: Route[] = [
    { id: 6, path: "/gerencia-execucao", name: "Gerência Execução" },
    { id: 7, path: "/custo", name: "Custo" }
].sort((a, b) => a.name.localeCompare(b.name));;

export const routeLider: Route[] = [
    ...routesInternalBase,
    { id: 6, path: "/gerencia-execucao", name: "Gerência Execução" }
].sort((a, b) => a.name.localeCompare(b.name));
